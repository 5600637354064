import React, { Fragment } from 'react'
// TODO: Remove CssBaseline
import CssBaseline from '@material-ui/core/CssBaseline'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { GlobalStyle } from '../styles/global'
import { MainMenu } from './MainMenu'
import faviconAppleTouch from '../images/favicon/apple-touch-icon.png'
import favicon32 from '../images/favicon/favicon-32x32.png'
import favicon16 from '../images/favicon/favicon-16x16.png'
import faviconSafariPinned from '../images/favicon/safari-pinned-tab.svg'
import logo from '../images/logo-with-aa.svg'
import { v } from '../styles/variables'

export const Root = ({ children }) => (
  <Fragment>
    <CssBaseline />
    <GlobalStyle />
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href={faviconAppleTouch} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="mask-icon" href={faviconSafariPinned} color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
    <PageHeader>
      <Logo src={logo} width={200} height={125} />
      <MainMenu />
    </PageHeader>
    <ContentWrapper>{children}</ContentWrapper>
  </Fragment>
)

const ContentWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: ${v.s2};
`
const PageHeader = styled.div`
  background: white;
  border-bottom: 1px solid ${v.c.gray[100]};
  padding-top: ${v.s1};
`
const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: ${v.s2};
`
