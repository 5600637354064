import React from 'react'
import styled from 'styled-components'
import { styleUnitMap, styleUnits } from './styleUnits'

export const clean = Cmp => {
  const CleanCmp = styled(props => {
    const cleaned = {}
    for (const p in props) {
      if (styleUnitMap[p] === undefined) cleaned[p] = props[p]
    }
    return <Cmp {...cleaned} />
  })`
    ${styleUnits};
  `
  return CleanCmp
}

export const DIV = clean('div')
export const H2 = clean('h2')
export const H3 = clean('h3')
export const P = clean('p')
export const CleanButton = clean('button')
