import { colors } from './colors'
import { createShadow } from './helpers/createShadow'

export const v = {
  c: colors,

  borderRadius: '4px',
  shadowSmall: createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
  shadowLargest: createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
}

// Initial 0 must remain a number so that it doesn't get turned into a decimal in the below for-loop
export const spacingScale = [
  0,
  '025',
  '05',
  '075',
  '1',
  '15',
  '2',
  '25',
  '3',
  '35',
  '4',
]

for (const scaleItem of spacingScale) {
  const val =
    scaleItem.length > 1 ? `${scaleItem[0]}.${scaleItem.slice(1)}` : scaleItem
  v[`s${scaleItem}`] = val ? `${val}rem` : 0
}

v.sAuto = 'auto'
spacingScale.push('Auto')
