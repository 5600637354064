import { emails } from './emails'

export const meetings = {
  women: {
    hooper: {
      title: 'Hooper Women',
      address: '1535 N Williams Ave, Portland, OR 97232',
      map:
        'https://www.google.com/maps/place/Hooper+Detox+Stabilization+Center/@45.534033,-122.666898,15z/data=!4m5!3m4!1s0x0:0x225594a6a4da318c!8m2!3d45.534033!4d-122.666898',
      times: ['Wednesday 7pm', 'Sunday 10am'],
    },
    depaul: {
      title: 'De Paul Women',
      address: '1312 SW Washington St, Portland, OR 97205',
      map:
        'https://www.google.com/maps/place/De+Paul+Treatment+Centers/@45.5220755,-122.6849404,15z/data=!4m5!3m4!1s0x0:0xd2f19a1ec9da5174!8m2!3d45.5220755!4d-122.6849404',
      times: ['Saturday 8pm'],
    },
    nara: {
      title: 'NARA Women',
      address: '17645 NW St Helens Rd, Portland, OR 97231',
      map:
        'https://www.google.com/maps/place/NARA+Residential+Treatment+Center/@45.650111,-122.850296,15z/data=!4m5!3m4!1s0x0:0xba30b086037f415b!8m2!3d45.650111!4d-122.850296',
      times: ['Monday 6:30pm'],
    },
    letty: {
      title: 'Letty Owings Center',
      address: '2545 NE Flanders St, Portland, OR 97232',
      map:
        'https://www.google.com/maps/place/Letty+Owings+Center/@45.5260942,-122.6394725,15z/data=!4m2!3m1!1s0x0:0xf5da04d49b352aec?sa=X&ved=0ahUKEwiw7ePtyKLcAhVzFjQIHWRaC5IQ_BIIbjAK',
      times: ['Wednesday 7pm'],
    },
  },
  men: {
    hooper: {
      title: 'Hooper Men',
      address: '1535 N Williams Ave, Portland, OR 97232',
      map:
        'https://www.google.com/maps/place/Hooper+Detox+Stabilization+Center/@45.534033,-122.666898,15z/data=!4m5!3m4!1s0x0:0x225594a6a4da318c!8m2!3d45.534033!4d-122.666898',
      times: ['Wednesday 7pm', 'Sunday 10am'],
    },
    depaul: {
      title: 'De Paul Men',
      address: '1312 SW Washington St, Portland, OR 97205',
      map:
        'https://www.google.com/maps/place/De+Paul+Treatment+Centers/@45.5220755,-122.6849404,15z/data=!4m5!3m4!1s0x0:0xd2f19a1ec9da5174!8m2!3d45.5220755!4d-122.6849404',
      times: ['Friday 8pm', 'Saturday 8pm', 'Sunday 7pm'],
    },
    depaulBoys: {
      title: 'De Paul Boys',
      address: '4310 NE Killingsworth St, Portland, OR 97218',
      map:
        'https://www.google.com/maps/place/De+Paul+Treatment+Centers+Youth+%26+Family+Center/@45.5616765,-122.6177897,15z/data=!4m5!3m4!1s0x0:0x48afff173f3fccea!8m2!3d45.5616765!4d-122.6177897',
      times: ['Sunday 7:30pm'],
    },
    nara: {
      title: 'NARA Men',
      address: '17645 NW St Helens Rd, Portland, OR 97231',
      map:
        'https://www.google.com/maps/place/NARA+Residential+Treatment+Center/@45.650111,-122.850296,15z/data=!4m5!3m4!1s0x0:0xba30b086037f415b!8m2!3d45.650111!4d-122.850296',
      times: ['Monday 6:30pm'],
    },
    tigard: {
      title: 'Tigard Recovery Center',
      address: '10362 SW McDonald St, Portland, OR 97224',
      map:
        'https://www.google.com/maps/place/Tigard+Recovery+Center/@45.4181081,-122.7860787,17z/data=!3m1!4b1!4m5!3m4!1s0x54950d370c575ea1:0xe8bba9a585e94a48!8m2!3d45.4181044!4d-122.78389',
      times: ['Friday 7pm'],
    },
    morrison: {
      title: 'Morrison Center Breakthrough',
      address: '11035 NE Sandy Blvd, Portland, OR 97220',
      map:
        'https://www.google.com/maps/place/Morrison+Center+Breakthrough/@45.558895,-122.549162,15z/data=!4m5!3m4!1s0x0:0xe1067f46f00e10f1!8m2!3d45.558895!4d-122.549162',
      times: ['Wednesday 5pm'],
    },
    salvation: {
      title: 'Salvation Army',
      address: '6855 NE 82nd Ave, Portland, OR 97220',
      map:
        'https://www.google.com/maps/place/The+Salvation+Army+Adult+Rehabilitation+Center/@45.572193,-122.57927,15z/data=!4m5!3m4!1s0x0:0xb972a546011f7a49!8m2!3d45.572193!4d-122.57927',
      times: ['Friday 7pm', 'Sunday 3pm'],
    },
  },
}

export const getVolunteerUrl = (title, time) => {
  time = time ? time.replace(/ /g, '+') : ''
  if (time) time = ` - ${time}`
  return emails.joinCommitment(`${title}${time}`)
}
