import React from 'react'
import styled from 'styled-components'
import { sharedStyles } from '../styles/helpers/sharedStyles'
import { v } from '../styles/variables'
import { Link } from './misc'
import { styleUnits } from './styleUnits'
import { CleanButton } from './tags'

function setButtonStyles({ text, background }) {
  let bgNormal
  let bgHover
  let bgActive

  if (background) {
    const bgColor = v.c[background]
    bgNormal = `background: linear-gradient(${bgColor[300]}, ${bgColor[400]});`
    bgHover = `background: linear-gradient(${bgColor[400]}, ${bgColor[500]})`
    bgActive = `background: linear-gradient(${bgColor[500]}, ${bgColor[600]})`
  } else {
    bgHover = `background: ${v.c.darken[15]};`
    bgActive = `background: ${v.c.darken[25]};`
  }

  return `
    color: ${text};
    text-decoration: none;
    padding: ${v.s05};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    border-radius: ${v.borderRadius};
    text-align: center;
    display: inline-block;
    ${bgNormal || ''}

    &:hover {
      color: ${text};
      ${bgHover}
    }
    &:active {
      ${bgActive}
    }
  `
}

const StyledButton = styled(CleanButton)`
  ${setButtonStyles};
  ${styleUnits};
`

export const Button = props => {
  let as
  if (props.to) {
    as = Link
  } else if (props.href) {
    as = 'a'
  }
  return <StyledButton as={as} {...props} />
}
Button.defaultProps = { text: 'white', background: 'green' }

export const IconButton = styled(CleanButton)`
  font-family: Material Icons;
  line-height: normal;
`
const BaseTextButton = styled(CleanButton)`
  padding: 0;
  ${sharedStyles.a};
`
export const TextButton = props => {
  let as
  if (props.to) {
    as = Link
  } else if (props.href) {
    as = 'a'
  }
  return <BaseTextButton as={as} {...props} />
}
