import React from 'react'
import styled from 'styled-components'
import { emails } from '../emails'
import { Link } from '../ui/misc'
import { url } from '../urls'
import { v } from '../styles/variables'

export const MainMenu = () => (
  <MenuWrapper>
    <MenuLink to={url.home}>Home</MenuLink>
    <MenuLink to={url.ourMission}>Our Mission</MenuLink>
    <MenuLink to={url.getInvolved}>Get Involved</MenuLink>
    <MenuLink to={url.commitments}>Current Commitments</MenuLink>
    <MenuLink to={url.request}>Request a Meeting</MenuLink>
    <MenuLink to={emails.contact()}>Contact</MenuLink>
    <MenuLink to={url.donate}>Donate</MenuLink>
  </MenuWrapper>
)

const MenuLink = styled(Link).attrs({
  activeClassName: 'active',
  exact: true,
})`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: ${v.c.gray.main};
  padding-bottom: ${v.s025};

  &.active {
    color: ${v.c.green.dark};
    border-bottom: 2px solid ${v.c.green.dark};
    pointer-events: none;
  }
  &:not(:last-child) {
    margin-right: ${v.s1};
  }
  &:hover {
    color: ${v.c.gray.light};
  }
`
const MenuWrapper = styled.div`
  text-align: center;
  margin-bottom: -1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 ${v.s1};
`
