import styled from 'styled-components'
import { v } from '../styles/variables'
import { styleUnits } from './styleUnits'
import { DIV } from './tags'

export const Card = styled(DIV)`
  border-radius: ${v.borderRadius};
  background: white;
  padding: ${v.s1};
  box-shadow: ${v.shadowSmall};
  ${styleUnits};
`
