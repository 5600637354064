function buildHref(data) {
  const parts = []
  for (const k in data) {
    let v = data[k]
    if (Array.isArray(v)) {
      v = encodeURIComponent(v.join('\n\n'))
    } else {
      v = encodeURIComponent(v)
    }
    parts.push(`${encodeURIComponent(k)}=${v}`)
  }
  return `mailto:hello@pnwhi.org?${parts.join('&')}`
}

export const emails = {
  commitmentAvailability: buildHref({
    subject: "I'd like to get involved in H&I",
    body: [
      'Gender:',
      'Name (including last name or initial):',
      'Phone Number:',
      'Sobriety Date:',
      'Which days of the week and at what time are you available?',
    ],
  }),

  joinCommitment(commitment) {
    const commitmentStr = commitment ? `\n${commitment}` : ''
    return buildHref({
      subject: "I'd like to join a commitment",
      body: [
        'Phone Number:',
        'Sobriety Date:',
        `Which commitment do you want to join?${commitmentStr}`,
      ],
    })
  },

  requestMeeting: buildHref({
    subject: 'H&I meeting request',
    body: [
      'Facility Name:',
      'Facility Location:',
      'Tell us about your facility -- age range, gender, etc. Also let us know if you have a particular day and time in mind.',
    ],
  }),

  contact: (subject = '') => buildHref({ subject }),
}
