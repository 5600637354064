/**
 * Pallete's built using http://mcg.mbitson.com
 */

export const colors = {
  green: {
    50: '#e5f3e0',
    100: '#bfe1b3',
    200: '#94ce80',
    300: '#69ba4d',
    400: '#49ab26',
    500: '#299c00',
    600: '#249400',
    700: '#1f8a00',
    800: '#198000',
    900: '#0f6e00',
    A100: '#a3ff9d',
    A200: '#74ff6a',
    A400: '#44ff37',
    A700: '#2cff1e',
  },
  blue: {
    50: '#e0f1f9',
    100: '#b3dbf0',
    200: '#80c4e6',
    300: '#4dacdb',
    400: '#269ad4',
    500: '#0088cc',
    600: '#0080c7',
    700: '#0075c0',
    800: '#006bb9',
    900: '#0058ad',
    A100: '#d7e9ff',
    A200: '#a4ccff',
    A400: '#71b0ff',
    A700: '#58a1ff',
  },
  gray: {
    50: '#f0f0f0',
    100: '#d9d9d9',
    200: '#c0c0c0',
    300: '#a6a6a6',
    400: '#939393',
    500: '#808080',
    600: '#787878',
    700: '#6d6d6d',
    800: '#636363',
    900: '#505050',
  },
  red: {
    50: '#f9e0e0',
    100: '#f0b3b3',
    200: '#e68080',
    300: '#db4d4d',
    400: '#d42626',
    500: '#cc0000',
    600: '#c70000',
    700: '#c00000',
    800: '#b90000',
    900: '#ad0000',
    A100: '#ffd7d7',
    A200: '#ffa4a4',
    A400: '#ff7171',
    A700: '#ff5858',
  },
  darken: {
    15: 'rgba(0, 0, 0, .15)',
    25: 'rgba(0, 0, 0, .25)',
  },
}
Object.keys(colors).forEach(c => {
  colors[c].light = colors[c][300]
  colors[c].main = colors[c][500]
  colors[c].dark = colors[c][700]
})
