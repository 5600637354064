import { v } from '../variables'

export const sharedStyles = {
  a: `
    color: ${v.c.green.main};
    transition: color .15s ease-in;
    text-decoration: underline;

    &:hover {
      color: ${v.c.green.light};
    }
  `,
}
