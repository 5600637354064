import { createGlobalStyle } from 'styled-components'
import { colors } from './colors'
import { sharedStyles } from './helpers/sharedStyles'
import { v } from './variables'

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    line-height: 1.5;
    font-family: Roboto, sans-serif;
  }

  button {
    line-height: 1.5;
  }

  a {
    ${sharedStyles.a};
  }

  p {
    margin-bottom: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  ol,
  ul {
    padding-left: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    font-weight: normal;
    color: black;
    margin-top: 0; /* because they're used as page headers exclusively */
  }

  h3 {
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
    margin-top: 0;
    font-weight: 500;
    color: black;
  }

  strong {
    font-weight: 500 !important;
  }

  i {
    display: inline-block;
    font-style: normal;
    font-family: Material Icons;
    vertical-align: bottom;
    margin-bottom: 1px;
  }

  button {
    appearance: none;
    cursor: pointer;
    background: none;
    border: 0;
    font-size: inherit;
    border-radius: ${v.borderRadius};

    &:active {
      outline: none;
    }
  }

  input[type="text"] {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid ${colors.gray[200]};
    width: 100%;
    font-size: inherit;

    &:focus {
      outline: 0;
      border-bottom-color: ${colors.green.light}
    }
  }
`
