import { spacingScale, v } from '../styles/variables'

export const styleUnitMap = {
  // Display
  block: 'display: block;',

  // Flexbox
  flex: 'display: flex;',
  flexWrap: 'display: flex; flex-wrap: wrap',
  flexAuto: 'flex: auto;',
  verticalCenter: 'display: flex; align-items: center;',
  flexTop: 'display: flex; align-items: start;',

  // Positioning
  relative: 'position: relative;',
  absolute: 'position: absolute;',

  // Typography
  center: 'text-align: center;',
  hoverUnderline: `
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  `,

  // Processed differently by the styleUnits function. Included in this object for exclusion by <CleanTag>
  color: null,
  bg: null,
  exact: null,
}

const directions = ['top', 'bottom', 'left', 'right']

for (const scaleItem of spacingScale) {
  const spacing = v[`s${scaleItem}`]

  for (const property of ['margin', 'padding']) {
    const p = property[0]
    styleUnitMap[`${p}${scaleItem}`] = `${property}: ${spacing};` // margin: 1rem
    styleUnitMap[
      `${p}x${scaleItem}`
    ] = `${property}-left: ${spacing}; ${property}-right: ${spacing};` // margin-left, margin-right
    styleUnitMap[
      `${p}y${scaleItem}`
    ] = `${property}-top: ${spacing}; ${property}-bottom: ${spacing};` // margin-top, margin-bottom

    for (const direction of directions) {
      const name = `${p}${direction[0]}${scaleItem}`
      const val = `${property}-${direction}: ${spacing};`
      styleUnitMap[name] = val // margin-direction
      if (scaleItem !== 'Auto') {
        styleUnitMap[`${name}lc`] = `&:not(:last-child) { ${val} }` // last-child
        if (scaleItem)
          styleUnitMap[
            `${p}${direction[0]}n${scaleItem}`
          ] = `${property}-${direction}: -${spacing};` // negative
      }
    }
  }

  for (const direction of directions) {
    styleUnitMap[`${direction[0]}${scaleItem}`] = `${direction}: ${spacing};` // left, right, top, bottom
  }
}

export function styleUnits(props) {
  let str = ''
  for (const key in props) str += styleUnitMap[key] || ''
  if (props.color) str += `color: ${props.color};`
  if (props.bg) str += `background: ${props.bg};`
  return str
}
