import React from 'react'
import { Root } from '../components/Root'
import { emails } from '../emails'
import { getVolunteerUrl, meetings } from '../meetings'
import { v } from '../styles/variables'
import styled from 'styled-components'
import { TextButton } from '../ui/buttons'
import { Card } from '../ui/cards'
import { Link } from '../ui/misc'
import { DIV, H2, P } from '../ui/tags'

const Commitments = () => {
  return (
    <Root>
      <H2 mb0>Commitments</H2>
      <P mt0 color="dark">
        We are currently coordinating the following commitments. Click the{' '}
        <em>Volunteer</em> link next to any of them to tell us you&apos;d like
        to help out. You can also just{' '}
        <TextButton to={emails.commitmentAvailability}>
          let us know your general availability
        </TextButton>{' '}
        and we&apos;ll reach out to you.
      </P>
      <DIV mtn2 mrn1 flexWrap>
        <GenderCard>
          <h2>Women</h2>
          {/* TODO: reconfirm all meeting times... not sure if my offline
            version of the facility coordinators file was up to date */}
          {Reflect.ownKeys(meetings.women).map(k => {
            const meeting = meetings.women[k]
            return (
              <Meeting
                key={k}
                title={meeting.title}
                address={meeting.address}
                map={meeting.map}
                times={meeting.times}
              />
            )
          })}
        </GenderCard>

        <GenderCard>
          <h2>Men</h2>
          {Reflect.ownKeys(meetings.men).map(k => {
            const meeting = meetings.men[k]
            return (
              <Meeting
                key={k}
                title={meeting.title}
                address={meeting.address}
                map={meeting.map}
                times={meeting.times}
              />
            )
          })}
        </GenderCard>
      </DIV>
    </Root>
  )
}

const Meeting = styled(({ address, map, title, times }) => (
  <div>
    <strong>{title}</strong>
    <Link to={map} color="gray" hoverUnderline block>
      <i>place</i>
      <span>{address}</span>
    </Link>
    <DIV mb1 mx0 color={v.c.gray.main}>
      {times.map(time => (
        <div key={time}>
          {time}
          &nbsp; • &nbsp;
          <Link to={getVolunteerUrl(title, time)} hoverUnderline>
            Volunteer
          </Link>
        </div>
      ))}
    </DIV>
  </div>
))`
  &:not(:first-child) {
    margin-top: ${v.s15};
    padding-top: ${v.s15};
    border-top: 1px solid ${v.c.gray[100]};
  }
`
const GenderCard = styled(Card)`
  margin: ${v.s2} ${v.s1} 0 0;
  flex: auto;
`

export default Commitments
