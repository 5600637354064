import GatsbyLink from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import { clean } from './tags'

const CleanOutboundLink = clean(OutboundLink)
const CleanGatsbyLink = clean(GatsbyLink)

export const Link = ({ to, activeClassName, exact, ...props }) => {
  if (to.startsWith('http') || to.startsWith('mailto:')) {
    return (
      <CleanOutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...props}
      />
    )
  }

  // Only add it back on if it exists. If it doesn't exist, it's possible that a normal React Router
  // `<Link>` will get created (https://goo.gl/KYCRFk) rather than an `<NavLink>`, in which case including an
  // `activeClassName` prop (even if undefined) will throw a React unknown prop warning.
  if (activeClassName) props.activeClassName = activeClassName
  return <CleanGatsbyLink to={to} exact={exact} {...props} />
}
